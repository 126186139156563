import config from "../config";
import _ from "lodash";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import {
  Client,
  JobQueryResponse,
  Job,
  JobFilterOptions,
  JobQueryMetaData,
  QueryParams,
} from "@sumit-platforms/types";

import { JobService } from "@sumit-platforms/ui-bazar/services";

const jobService = JobService({ config });

type UseJobType =
  | "userAssignments"
  | "availableJobs"
  | "allJobs"
  | "walletMonth"
  | "communityMemberMonth";
interface UseJobProps {
  jobType: UseJobType;
}
const useJobs = ({
  jobType,
}: UseJobProps): {
  jobs: Job[];
  getJobs: ({
    query,
  }: {
    query?: QueryParams<JobFilterOptions> | null;
  }) => Promise<void>;
  jobQueryMetaData: JobQueryMetaData;
  setJobs: Dispatch<SetStateAction<Job[]>>;
  getJobQueryMetaData: (query?: QueryParams<JobFilterOptions>) => Promise<void>;
  totalJobs: number;
  hasMore: boolean;
  isLoading: boolean;
} => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalJobs, setTotalJobs] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [jobQueryMetaData, setJobQueryMetaData] = useState<
    JobQueryMetaData & { totalJobs: number }
  >({
    projectNames: [],
    maxDuration: 0,
    clients: [],
    totalJobs: 0,
    communityMembers: [],
    invoices: [],
    users: [],
  });
  const latestUpdate = useRef<number>();

  const getJobQueryMetaData = async (query?: QueryParams<JobFilterOptions>) => {
    const jobQueryMetaData = await fetchJobsMetadataByType(query);
    setJobQueryMetaData(jobQueryMetaData);
  };

  const fetchJobsMetadataByType = (query?: QueryParams<JobFilterOptions>) => {
    switch (jobType) {
      case "availableJobs": {
        return jobService.getAvailableJobQueryMetaData(query);
      }
      case "userAssignments": {
        return jobService.getAssignedJobQueryMetaData(query);
      }
      case "allJobs": {
        return jobService.getJobQueryMetaData(query);
      }
    }
  };

  const fetchJobsByType = (
    query?: QueryParams<JobFilterOptions>
  ): Promise<JobQueryResponse> => {
    switch (jobType) {
      case "availableJobs": {
        return jobService.getAvailableJobs(query);
      }
      case "userAssignments": {
        return jobService.getAssignedJobs(query);
      }
      case "allJobs": {
        return jobService.getJobs(query);
      }
      case "walletMonth": {
        return jobService.getWalletMonthJobs(query);
      }
      case "communityMemberMonth": {
        const { idUser } = query?.filters || {};
        if (!query || !idUser) {
          throw new Error("Missing params in useJobs");
        }
        return jobService.getCommunityMemberJobsByMonth(+idUser, query);
      }
    }
  };

  const isTimestampLatest = (timestamp?: number) => {
    const latest = latestUpdate.current;
    if (latest && timestamp) {
      const isLatest = timestamp > latest;
      return isLatest;
    }
    return true;
  };
  const getJobs = async ({
    query,
  }: {
    query?: QueryParams<JobFilterOptions> | null;
  }) => {
    try {
      setIsLoading(true);
      query = { ...(query || {}), timestamp: Date.now() };
      const { jobs, totalJobs, timestamp } = await fetchJobsByType(query);

      const isLatest = isTimestampLatest(timestamp);
      if (!isLatest) return;
      latestUpdate.current = timestamp;

      setJobs((prev: any) => {
        if (query && _.isNumber(query?.offset) && query.offset > 0) {
          const aggregatedJobs = [...prev, ...jobs];
          setHasMore(aggregatedJobs.length !== totalJobs);
          return aggregatedJobs;
        } else {
          setHasMore(jobs.length !== totalJobs);
          return jobs;
        }
      });
      setTotalJobs(totalJobs);
    } catch (e: any) {
      throw new Error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    jobs,
    getJobs,
    setJobs,
    jobQueryMetaData,
    getJobQueryMetaData,
    totalJobs,
    hasMore,
    isLoading,
  };
};

export default useJobs;
