import { useEffect, useState } from "react";

export const useVisibility = (elementRef: any, enabled: boolean) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the default root
        rootMargin: "700px", // Increase the top margin for earlier visibility
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [elementRef]);

  return { isVisible, elementRef };
};
