import config from "../../config";
import _ from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";
import classNames from "classnames";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "@sumit-platforms/ui-bazar/store";
import { useUser } from "../../store";
import {
  JOBS_INITIAL_ORDER,
  JOBS_INITIAL_ORDER_BY,
  JOBS_INITIAL_QUERY_LIMIT,
  JOBS_INITIAL_QUERY_OFFSET,
  TODAY_MIDNIGHT,
} from "@sumit-platforms/ui-bazar/constants";

import { JobService } from "@sumit-platforms/ui-bazar/services";
import { useGlobalData } from "../../store/globalData";
import AssignmentService from "../../services/assignmentService";
import useAssignments from "../../hooks/useAssignments";
import useJobs from "../../hooks/useJobs";
import {
  Assignment,
  AssignStatus,
  Client,
  Job,
  JobFilterOptions,
  JobStatus,
  jobTypesOptions,
  MyAssignment,
  QueryParams,
  Tag,
  TaskStatus,
  UsersJobs,
} from "@sumit-platforms/types";
import {
  AssignmentRequest as AssignmentRequestComponent,
  ConfirmModal,
  ContextOption,
  GeneralTable,
  ModalType,
  ScrollerContainer,
  SearchAndFilters,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import {
  AvailableFilters,
  useFeatureFlag,
  useFilters,
  useHeadCells,
  useQuery,
  useSortOptions,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";

import {
  isJobReadyForQc,
  isJobReadyForTranscribe,
  getJobIncomeByStatus,
  getJobIncomeByAssignments,
} from "@sumit-platforms/ui-bazar/utils";

import "./MyJobs.scss";

const jobService = JobService({ config });
interface JobRow {
  id: number;
  name: string;
  client: Client;
  type: string;
  status: JobStatus;
  duration: number;
  income: string;
  uploaded: Date;
  delivery: Date;
  contextMenu: any;
  job: Job;
  assignment?: Assignment;
  disabled?: boolean;
  isLoading?: boolean;
}

const filterKeys: AvailableFilters[] = [
  "jobStatus",
  "projectName",
  "type",
  "languages",
  "length",
  "dueBy",
  "uploaded",
  "client",
];

const MyJobs = () => {
  const { t } = useTranslation();
  const {
    jobs,
    getJobs,
    hasMore,
    isLoading: isJobsLoading,
    totalJobs,
    getJobQueryMetaData,
    jobQueryMetaData,
  } = useJobs({ jobType: "userAssignments" });
  const { user } = useUser();

  const [jobRows, setJobRows] = useState<JobRow[]>([]);
  const { jobTypes, setToast } = useGlobalData();
  const { toastSuccess, toastError } = useToast({ setToast });

  const {
    getAssignedJobsByIdUser,
    myAssignmentRequests,
    isLoading: isAssignmentsLoading,
  } = useAssignments();
  const [tagsPool] = useState<Tag[]>([]);
  const [isAssignmentRequestsOpen, setIsAssignmentRequestsOpen] =
    useState(false);
  const { filtersScheme } = useFilters({
    metaData: { ...jobQueryMetaData, jobTypes, tags: tagsPool },
    filterKeys,
  });
  const { sortOptions } = useSortOptions([
    "created_at",
    "name",
    "duration",
    "status",
  ]);

  const defaultQuery: JobFilterOptions = useMemo(
    () => ({
      status: [],
      projectName: [],
      type: [],
      inputLanguage: [],
      outputLanguage: [],
      durationStart: 0,
      durationEnd: jobQueryMetaData.maxDuration,
      deliveryStart: TODAY_MIDNIGHT,
      deliveryEnd: TODAY_MIDNIGHT,
      uploadedStart: TODAY_MIDNIGHT,
      uploadedEnd: TODAY_MIDNIGHT,
      clientIds: [],
    }),
    [jobQueryMetaData]
  );

  const { headCells } = useHeadCells({
    headCellsKeys: [
      "id",
      "name",
      "client",
      "type",
      "status",
      "duration",
      "transcribeDelivery",
      "income",
      "contextMenu",
    ],
    cellLink: {
      name: (r) => `/job/${r.id}`,
    },
    tableContextCallBack: getTableContext,
    styles: {
      id: {
        padding: 4,
      },
      duration: { width: "6%" },
      name: { width: "22%" },
      status: { width: "12%" },
      deliveryWithTranscribeDelivery: { width: "10%" },
      type: { width: "10%" },
    },
  });
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const pendingAssignments = useMemo(
    () =>
      myAssignmentRequests.filter(
        (request) => request.assignment.assignStatus === AssignStatus.pending
      ),
    [myAssignmentRequests, jobs, jobRows]
  );

  const {
    onScroll,
    onSearch,
    onFilterChange,
    onSort,
    loading: isQueryLoading,
  } = useQuery<JobFilterOptions>({
    queryLimit: JOBS_INITIAL_QUERY_LIMIT,
    queryOffset: JOBS_INITIAL_QUERY_OFFSET,
    order: JOBS_INITIAL_ORDER,
    orderBy: JOBS_INITIAL_ORDER_BY,
    onBuildQuery,
    onResetOffset: () => {
      setJobRows([]);
    },
  });

  useEffect(() => {
    getJobQueryMetaData();
    getAssignedJobsByIdUser();
  }, []);

  useEffect(() => {
    if (_.isArray(jobs) && !isJobsLoading) {
      const newJobRows = jobs.map(createJobRow);
      setJobRows(newJobRows);
    }
  }, [jobs]);

  const onUpdateAssignment = async () => {
    await getJobs({ query: null });
    await getAssignedJobsByIdUser();
  };

  const closeModal = () => {
    clearModalContent();
  };

  const openConfirmModal = (
    title: any,
    message: any,
    confirm: () => Promise<any>,
    closeAfterConfirm = true,
    modalType: ModalType
  ): void => {
    setModalType(modalType);
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  const onJobAcceptConfirm = async (assignmentRequest: MyAssignment) => {
    try {
      if (assignmentRequest.job && assignmentRequest.assignment) {
        await AssignmentService.acceptAssignedJob({
          idUsersJobs: assignmentRequest.assignment.idUsersJobs,
        });
      }
      toastSuccess(t("accept_assignment_success"));
    } catch (e) {
      toastError(t("accept_assignment_fail"), e);
    } finally {
      await onUpdateAssignment();
    }
  };

  const onRejectConfirm = async (assignmentRequest: MyAssignment) => {
    try {
      if (assignmentRequest.job && assignmentRequest.assignment) {
        await AssignmentService.rejectAssignedJob({
          idUsersJobs: assignmentRequest.assignment.idUsersJobs,
        });
        await onUpdateAssignment();
      }
      toastSuccess(t("reject_assignment_success"));
    } catch (e) {
      toastError(t("reject_assignment_fail"), e);
    }
  };

  const onLeaveConfirm = async (jobRow: JobRow) => {
    try {
      if (jobRow?.assignment?.task && jobRow.id) {
        await AssignmentService.resignJob({
          idUsersJobs: jobRow.assignment.idUsersJobs,
        });
        await onUpdateAssignment();
      }
      toastSuccess(t("leave_assignment_success"));
    } catch (e) {
      toastError(t("leave_assignment_fail"), e);
    }
  };

  const onDoneConfirm = async (jobRow: JobRow) => {
    try {
      if (jobRow?.assignment?.task && jobRow.id) {
        await AssignmentService.doneAssignment({
          idJob: jobRow.id,
          task: jobRow.assignment.task,
        });
        await onUpdateAssignment();
        toastSuccess(t("done_assignment_success"));
      }
    } catch (e) {
      toastError(t("done_assignment_failed"), e);
    }
  };

  const getJobAcceptModalTitle = (assignmentRequest: MyAssignment) => (
    <Typography sx={{ fontSize: 18 }}>
      <Trans
        i18nKey="accept_assign_job_modal_title"
        values={{ jobName: assignmentRequest.job.name }}
        components={{ strong: <strong />, br: <br /> }}
      />
    </Typography>
  );

  const getJobAcceptModalText = (assignmentRequest: MyAssignment) => (
    <Typography>
      <Trans
        i18nKey="accept_assign_job_modal_text"
        values={{
          jobName: assignmentRequest.job.name,
          price: jobService.getPriceByTask({
            job: assignmentRequest.job,
            task: assignmentRequest.assignment.task,
          }),
          deadline: format(
            new Date(
              assignmentRequest.job.transcribeDelivery ||
                assignmentRequest.job.delivery
            ),
            "dd-MM-yyyy - HH:mm:ss"
          ),
        }}
        components={{ strong: <strong />, br: <br /> }}
      />
    </Typography>
  );

  const handleJobAccept = async (assignmentRequest: MyAssignment) => {
    if (
      isJobReadyForQc(assignmentRequest) ||
      isJobReadyForTranscribe(assignmentRequest)
    )
      openConfirmModal(
        getJobAcceptModalTitle(assignmentRequest),
        getJobAcceptModalText(assignmentRequest),
        () => onJobAcceptConfirm(assignmentRequest),
        true,
        "info"
      );
  };
  const handleJobReject = async (assignmentRequest: MyAssignment) => {
    openConfirmModal(
      t("reject_assign_job_modal_title"),
      t("reject_assign_job_modal_text"),
      () => onRejectConfirm(assignmentRequest),
      true,
      "warning"
    );
  };

  const getRelevantAssignment = (job: Job) => {
    const assignment = job.usersJobs?.find((userJob: UsersJobs) => {
      const isCurrentlyOnWork = userJob.taskStatus === TaskStatus.work;
      return isCurrentlyOnWork;
    });
    return assignment;
  };

  const handleLeaveJob = async (jobRow: JobRow) => {
    openConfirmModal(
      t("leave_assign_job_modal_title"),
      t("leave_assign_job_modal_text"),
      () => onLeaveConfirm(jobRow),
      true,
      "warning"
    );
  };

  const getJobDoneModalText = (jobRow: JobRow) => (
    <Trans
      i18nKey="done_assign_job_modal_text"
      values={{ jobName: jobRow.name }}
      components={{ strong: <strong /> }}
    />
  );

  const handleDoneJob = async (jobRow: JobRow) => {
    openConfirmModal(
      t("done_assign_job_modal_title"),
      getJobDoneModalText(jobRow),
      () => onDoneConfirm(jobRow),
      true,
      "info"
    );
  };

  function getTableContext(row: JobRow): ContextOption[] {
    const shouldDisableActions = !row.assignment;
    return [
      {
        name: t("done"),
        action: () => handleDoneJob(row),
        disabled: shouldDisableActions,
      },
      {
        name: t("leave"),
        action: () => handleLeaveJob(row),
        disabled: shouldDisableActions,
      },
      {
        name: t("open_in_ooona"),
        action: () => openJobInOoona(row.job),
        disabled: !isOoonaEditAllowed(row),
      },
    ];
  }

  const updateJobRow = (idJob: number, rowData: Partial<JobRow>) => {
    const updatedJobRows = _.clone(jobRows);
    const jobRowIndex = jobRows.findIndex((jr) => jr.id === idJob);
    console.log(jobRowIndex);
    updatedJobRows[jobRowIndex] = {
      ...updatedJobRows[jobRowIndex],
      ...rowData,
    };
    console.log(updatedJobRows);
    setJobRows(updatedJobRows);
  };

  async function openJobInOoona({ idJob }: { idJob: number }) {
    try {
      updateJobRow(idJob, { isLoading: true });
      const ooonaEditorUrl = await jobService.getOoonaEditorUrl(idJob);
      window.open(ooonaEditorUrl);
    } catch (err) {
      toastError(t("ooona_editor_open_failed"));
    } finally {
      updateJobRow(idJob, { isLoading: false });
    }
  }

  const isOoonaEditAllowed = useCallback(
    (jobRow: JobRow) =>
      [
        JobStatus.done,
        JobStatus.archive,
        JobStatus.review,
        JobStatus.transcribe,
      ].includes(jobRow.status) && jobTypesOptions[jobRow.type]?.allowOoona,
    [jobRows]
  );

  async function onBuildQuery({
    query,
  }: {
    query: QueryParams<JobFilterOptions>;
  }) {
    if (query.search || query.filters) {
      const _filtersQuery = { ...query.filters };
      if (
        _filtersQuery.status?.includes(JobStatus.stt) ||
        _filtersQuery.status?.includes(JobStatus.pending)
      ) {
        _filtersQuery.status = _.uniq([
          ..._filtersQuery.status,
          JobStatus.stt,
          JobStatus.pending,
        ]);
      }
      query.filters = _filtersQuery;
    }
    await getJobs({ query });
  }

  const createJobRow = (job: Job): JobRow => {
    const assignment = getRelevantAssignment(job);
    const disabled = !assignment;
    return {
      id: job.idJob,
      name: job.name,
      client: job.client,
      type: job.type.typeName,
      status: job.status,
      duration: job.duration || 0,
      uploaded: new Date(job.createdAt),
      delivery: new Date(job.delivery),
      contextMenu: null,
      income: getJobIncomeByAssignments({ job, idUser: user?.idUser }),
      job: job,
      disabled,
      assignment,
    };
  };

  return (
    <Grid
      className={"MyJobs Page"}
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} mb={3}>
        <h1 className="pageTitle">{t("my_jobs")}</h1>
        <Box
          sx={{
            cursor: pendingAssignments.length > 0 ? "pointer" : "auto",
            userSelect: pendingAssignments.length > 0 ? "none" : "auto",
            width: "100%",
          }}
          onClick={() => setIsAssignmentRequestsOpen(!isAssignmentRequestsOpen)}
          display={"inline-flex"}
          alignItems={"center"}
          gap={1}
          className="pageSubTitle"
        >
          {t("awaiting_your_acceptance")} ({pendingAssignments.length})
          {pendingAssignments.length > 0 && (
            <FontAwesomeIcon
              className={classNames("openAvailableAssignmentsArrow", {
                open: isAssignmentRequestsOpen,
              })}
              icon={faChevronRight}
            />
          )}
          <SpinningLoader
            className={`assignmentsLoader ${
              isAssignmentsLoading ? "" : "hidden"
            }`}
          />
        </Box>
      </Grid>
      <Grid item xs={11} className={"assignmentRequestsContainer"} pb={3}>
        <ScrollerContainer>
          {isAssignmentRequestsOpen
            ? !isAssignmentsLoading &&
              pendingAssignments.map((assignmentRequest) => {
                return (
                  <Box px={2} key={assignmentRequest.assignment.idUsersJobs}>
                    <AssignmentRequestComponent
                      assignment={assignmentRequest}
                      onAccept={() => handleJobAccept(assignmentRequest)}
                      onReject={() => handleJobReject(assignmentRequest)}
                    />
                  </Box>
                );
              })
            : null}
        </ScrollerContainer>
      </Grid>
      <Grid item xs={11} mb={3}>
        <h3 className="pageSubTitle">
          {`${t("showing")} ${jobRows?.length} ${t("from")} ${totalJobs}`}
        </h3>
      </Grid>
      <Grid item xs={11} mb={3}>
        <SearchAndFilters
          isLoading={isQueryLoading}
          direction={t("dir") as "ltr" | "rtl"}
          onSearch={onSearch}
          filters={filtersScheme}
          searchPlaceholder={t("search")}
          filterBtnTitle={t("filters")}
          title={t("filter_by")}
          defaultQuery={defaultQuery}
          onFilterChange={onFilterChange}
          onSortChange={onSort}
          sortOptions={sortOptions}
        />
      </Grid>
      <Grid item xs={11}>
        <GeneralTable
          headCells={headCells}
          rows={jobRows}
          onLoadMore={onScroll}
          loading={isQueryLoading}
          hasMore={hasMore}
          allowSelect={false}
          disablePointerEventsOnRowDisabled={true}
        />
      </Grid>
    </Grid>
  );
};

export default MyJobs;
