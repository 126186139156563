import config from "../../../../config";
import React, { useMemo } from "react";
import {
  Invoice,
  InvoiceUrgency,
  Job,
  JobInvoice,
  JobStatus,
} from "@sumit-platforms/types";
import { MultiSelect } from "@sumit-platforms/ui-bazar";

import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useInvoices } from "@sumit-platforms/ui-bazar/hooks";

interface JobPaymentEditProps {
  selectedJobs: Job[];
  updatedJobInvoice: Partial<JobInvoice>;
  handleJobInvoiceChange: ({
    key,
    value,
  }: {
    key: keyof JobInvoice;
    value: any;
  }) => any;
  createNewInvoice: ({
    idClient,
    invoiceNumber,
  }: {
    idClient: number;
    invoiceNumber: string;
  }) => Promise<Invoice>;
}

const JobInvoiceEdit = ({
  selectedJobs,
  updatedJobInvoice,
  handleJobInvoiceChange,
  createNewInvoice,
}: JobPaymentEditProps) => {
  const { t } = useTranslation();

  const idClient = useMemo(() => {
    return selectedJobs[0].client?.idClient || selectedJobs[0].idClient;
  }, [selectedJobs]);

  const { isLoading, invoices } = useInvoices({ idClient, config });

  const clientsInvoicesOptions = useMemo<
    { label: string; value: number }[]
  >(() => {
    const options = invoices.map((i) => ({
      value: i.idInvoice,
      label: i.invoiceNumber,
    }));
    const noInvoiceItem: any = {
      value: "no_invoice",
      label: t("no_invoice"),
    };
    options.unshift(noInvoiceItem);
    return options;
  }, [invoices]);

  const urgencyOptions = useMemo(() => {
    return Object.entries(InvoiceUrgency).map(([key, value]) => ({
      value,
      label: _.capitalize(key),
    }));
  }, []);

  const isAllJobsDone = useMemo(() => {
    return _.every(selectedJobs, (job) => job.status === JobStatus.done);
  }, [selectedJobs]);

  const handleCreateNewInvoice = async (invoiceNumber: string) => {
    if (!idClient) return;
    try {
      const newInvoice = await createNewInvoice({ idClient, invoiceNumber });
      clientsInvoicesOptions.push({
        value: newInvoice.idInvoice,
        label: newInvoice.invoiceNumber,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box display={"flex"} justifyContent={"space-between"} gap={2}>
      <MultiSelect
        loading={isLoading}
        label={t("invoice_number")}
        className={"invoiceNumberSelectInput"}
        options={clientsInvoicesOptions}
        onChange={(e) =>
          handleJobInvoiceChange({
            key: "idInvoice",
            value: e?.target?.value,
          })
        }
        selected={[updatedJobInvoice.idInvoice || ""]}
        placeholder={t("select_invoice_number")}
        isMulti={false}
        addNewOption={{
          onApprove: handleCreateNewInvoice,
        }}
      />
      <MultiSelect
        loading={isLoading}
        label={t("urgency")}
        className={"urgencySelectInput"}
        options={urgencyOptions}
        onChange={(e) =>
          handleJobInvoiceChange({
            key: "urgency",
            value: e?.target?.value,
          })
        }
        selected={[updatedJobInvoice.urgency || ""]}
        placeholder={t("select_urgency")}
        isMulti={false}
      />
    </Box>
  );
};

export default JobInvoiceEdit;
